import React from 'react';
import { Line } from "react-chartjs-2";

const options = {
  maintainAspectRatio: false
}

const ConsumptionGraph = (props) => {
  const {title, receipts} = props;

  const line = {
    labels: receipts.map((receipt) => receipt.periodoFactura).reverse(),
    datasets: [
      {
        label: 'Consumo',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(0,147,232,0.4)',
        borderColor: 'rgba(0,147,232,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(0,147,232,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(0,147,232,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: receipts.map((receipt) => receipt.metrosCubicos).reverse(),
      },
    ],
  };

  return(
    <>
      <h4>{title}</h4>
              <div
                className="chart-wrapper"
                style={{ height: 300 + "px", marginTop: 10 + "px" }}
              >
              <Line data={line} options={options} />
              </div>
    </>
  );
};

export default ConsumptionGraph;
