import React, { useState, useEffect } from "react";
import { Container, Row, Col, Alert } from "reactstrap";
import ConsumptionGraph from "../components/receipt/details/ConsumptionGraph";
import ReceiptsList from "../components/receipt/details/ReceiptsList";
import PajaReceiptsConsumer from "../components/receipt/details/PajaReceiptsConsumer";
import AppMessages from "../components/shared/AppMessages";
import NoData from "../components/common/NoData";
import Loading from "../components/common/Loading";
import NonMainNavbar from "components/Navbars/NonMainNavbar";
import AsadaFooter from "../components/AsdaFooter";

const subtitlePadding = {
  marginTop: "2vh",
  marginBottom: "2vh",
};

const ReceiptDetails = (props) => {
  const pajaNumero = props.match.params.pajaId;
  const [loading, setLoading] = useState(true);
  const [receipts, setReceipts] = useState([]);
  const [fetchResult, setFetchResult] = useState("");

  useEffect(() => {
    const handleFetch = (data) => {
      if (data === "failed") {
        setFetchResult(AppMessages.serviceUnavailable);
        setLoading(false);
      } else {
        setFetchResult("");
        setReceipts(data);
        setLoading(false);
      }
    };

    PajaReceiptsConsumer(pajaNumero, handleFetch);
  }, [pajaNumero]);

  if (loading) {
    return <Loading />;
  }

  if (receipts.length === 0) {
    return <NoData />;
  }

  return (
    <>
      <NonMainNavbar />
      <Container>
        <div style={{ height: "15vh" }} />
        {fetchResult !== "" && <Alert color="danger">{fetchResult}</Alert>}
        <Row>
          <Col xs="12">
            <h2>
              Detalle de la Paja: <strong>{pajaNumero}</strong> Cliente:{" "}
              <strong>{receipts[0].nombre}</strong>
            </h2>

            <ConsumptionGraph
              title="Consumo de los últimos 6 meses"
              receipts={receipts}
            />
            <h4 style={subtitlePadding}>Recibos</h4>
            <ReceiptsList receipts={receipts} />
          </Col>
        </Row>
      </Container>
      <div className="mt-3" />
      <AsadaFooter />
    </>
  );
};

export default ReceiptDetails;
