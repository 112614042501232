import React from "react";
import {
  Collapse,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Badge,
} from "reactstrap";

const ReceiptItem = (props) => {
  const { receipt, accordions, toggle, index } = props;

  const itemHeaderColor = () => {
    if (accordions[index]) {
      return 'bg-info'
    } else if (receipt.estado === 'PENDIENTE') {
      return 'bg-danger'
    } else {
      return ''
    }
  }

  const headerTextColor = () => {
    if (accordions[index] || receipt.estado === 'PENDIENTE') {
      return 'text-secondary'
    } else {
      return 'text-default'
    }
  }

  return (
    <>
      <ListGroupItem className={itemHeaderColor()}>
        <h6
          onClick={() => toggle(index)}
          className={headerTextColor()}
        >
          <b>Perido:</b>
          {receipt.periodoFactura} <b>Vencimiento:</b>{" "}
          {receipt.fechaVencimiento}
        </h6>
      </ListGroupItem>
      <Collapse isOpen={accordions[index]}>
        <ListGroup>
          <ListGroupItem className="bg-light">
            <Row>
              <Col xs="6" md="3" lg="2">
                Estado
              </Col>
              <Col xs="6" md="3" lg="2">
                <Badge
                  color={receipt.estado === "PENDIENTE" ? "danger" : "success"}
                >
                  {receipt.estado}
                </Badge>
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row>
              <Col xs="6" md="3" lg="2">
                Factura
              </Col>
              <Col xs="6" md="3" lg="2">
                {receipt.numeroFactura}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="bg-light">
            <Row>
              <Col xs="6" md="3" lg="2">
                Lectura Anterior
              </Col>
              <Col xs="6" md="3" lg="2">
                {receipt.lecturaAnterior}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row>
              <Col xs="6" md="3" lg="2">
                Lectura Actual
              </Col>
              <Col xs="6" md="3" lg="2">
                {receipt.lecturaActual}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="bg-light">
            <Row>
              <Col xs="6" md="3" lg="2">
                M. Cúbicos
              </Col>
              <Col xs="6" md="3" lg="2">
                {receipt.metrosCubicos}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row>
              <Col xs="6" md="3" lg="2">
                Tarifa Base
              </Col>
              <Col xs="6" md="3" lg="2">
                {"\u20A1"}
                {receipt.tarifaBase}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="bg-light">
            <Row>
              <Col xs="6" md="3" lg="2">
                Consumo
              </Col>
              <Col xs="6" md="3" lg="2">
                {"\u20A1"}
                {receipt.consumo}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row>
              <Col xs="6" md="3" lg="2">
                Hidrantes
              </Col>
              <Col xs="6" md="3" lg="2">
                {"\u20A1"}
                {receipt.hidrantes}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="bg-light">
            <Row>
              <Col xs="6" md="3" lg="2">
                Mora
              </Col>
              <Col xs="6" md="3" lg="2">
                {"\u20A1"}
                {receipt.moraCorta}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row>
              <Col xs="6" md="3" lg="2">
                Otros
              </Col>
              <Col xs="6" md="3" lg="2">
                {"\u20A1"}
                {receipt.arreglosOtros}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="bg-light">
            <Row>
              <Col xs="6" md="3" lg="2">
                Total
              </Col>
              <Col xs="6" md="3" lg="2">
                {"\u20A1"}
                {receipt.totalPagar}
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Collapse>
    </>
  );
};

export default ReceiptItem;
