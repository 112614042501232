import React, {useEffect, useState} from "react";
import PostItem from "./PostItem";
import { VerticalTimeline } from "react-vertical-timeline-component";

const PostList = () => {
  const [posts,setPosts]=useState([]);
  const getPosts=()=>{
    fetch('payloads/news.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setPosts(myJson)
      });
  }
  useEffect(()=>{
    getPosts()
  },[])
  if (posts.length === 0) {
    return <div>No hay noticias en este momento</div>;
  }

  return (
    <VerticalTimeline className='vertical-timeline-custom-line'>
      {posts.map((post, index) => (
        <PostItem key={index} post={post} />
      ))}
    </VerticalTimeline>
  );
};

export default PostList;
