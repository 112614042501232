import React from "react";
import { Media } from "reactstrap";
import { useHistory } from "react-router-dom";

const NavDropdownMediaItem = ({ linkTo, icon, title, body }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(linkTo);
  };

  return (
    <Media className="d-flex align-items-center" onClick={handleClick}>
      <div className="icon icon-shape bg-gradient-info rounded-circle text-white">
        <i className={icon} />
      </div>
      <Media body className="ml-3">
        <h6 className="heading text-primary mb-md-1">{title}</h6>
        <p className="description d-none d-md-inline-block mb-0">{body}</p>
      </Media>
    </Media>
  );
};

export default NavDropdownMediaItem;
