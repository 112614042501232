import store from "./session/Store";
import { getEmployees, getFounders, getMembers, getPosts } from "./CmsApi";

const fetchMembers = async () => {
  const setMembers = store.getActions().members.setMembers;

  const handleMembersFetch = (response) => {
    if (response !== "failed") {
      setMembers(response);
    }
  };

  return await getMembers(handleMembersFetch);
};

const fetchEmployees = async () => {
  const setEmployees = store.getActions().employees.setEmployees;

  const handleEmployeesFetch = (response) => {
    if (response !== "failed") {
      setEmployees(response.employees);
    }
  };

  return await getEmployees(handleEmployeesFetch);
};

const fetchFounders = async () => {
  const setFounders = store.getActions().founders.setFounders;

  const handleFoundersFetch = (response) => {
    if (response !== "failed") {
      setFounders(response.founders);
    }
  };

  return await getFounders(handleFoundersFetch);
};

const fetchPosts = async () => {
  const setPosts = store.getActions().posts.setPosts;

  const handleFetchPosts = (response) => {
    if (response !== "failed") {
      setPosts(response);
    }
  };

  return await getPosts(handleFetchPosts);
};

const initialize = async () => {
  await fetchMembers();
  await fetchEmployees();
  await fetchPosts();
  await fetchFounders();
};

export default initialize;
