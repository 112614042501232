import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import AppMessages from '../shared/AppMessages';

const NoData = (props) => {
  const {message} = props;


  return(
    <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardBody>{ (message)? message : AppMessages.noData}</CardBody>
            </Card>
          </Col>
        </Row>
      </div>
  )
}

export default NoData;