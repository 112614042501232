import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Col, Container, Row } from "reactstrap";
import AsadaFooter from "../components/AsdaFooter";
import NonMainNavbar from "../components/Navbars/NonMainNavbar";
import MemberCard from "../components/members/MemberCard";

const Members = (props) => {
  const [data,setData]=useState([]);
  const getData=()=>{
    fetch('payloads/members.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])
  const [employees,setEmployees]=useState([]);
  const getEmployees=()=>{
    fetch('payloads/employees.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setEmployees(myJson)
      });
  }
  useEffect(()=>{
    getEmployees()
  },[])

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <NonMainNavbar />
      <div style={{ height: "15vh" }} />
      <Container>
        <Row className="align-items-center justify-content-center mb-4">
          <Col xs="12">
            <h1>Miembros de Junta</h1>
          </Col>
        </Row>
        <Row>
          {data.map((member, index) => (
            <MemberCard key={index} member={member} />
          ))}
        </Row>

        <Row className="align-items-center justify-content-center mb-4 mt-6">
          <Col xs="12">
            <h1>Colaboradores</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactMarkdown children={employees}/>
          </Col>
        </Row>
      </Container>
      <div style={{ marginBottom: "3vh" }} />
      <AsadaFooter style={{ position: "relative", bottom: "0" }} />
    </>
  );
};

export default Members;
