import React from "react";
import { useHistory } from "react-router-dom";

const ReceiptRow = (props) => {
  const history = useHistory();
  const { receipt } = props;

  const handleRowClick = (paja) => {
    history.push(`/recibos/${paja}`);
  };

  return (
    <tr
      onClick={() => {
        handleRowClick(receipt.paja);
      }}
    >
      <td>{receipt.paja}</td>
      <td>{receipt.periodo}</td>
      <td>{receipt.nombre}</td>
      <td>{receipt.numeroFactura}</td>
      <td>{receipt.fechaVencimiento}</td>
    </tr>
  );
};

export default ReceiptRow;
