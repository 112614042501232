import React, { useEffect } from "react";
import lottie from "lottie-web";
import * as loadingAnimation from "../../assets/loading.json";
import { Row, Col } from "reactstrap";

const Loading = ({ margin = true }) => {
  let animRef = React.createRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: animRef,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loadingAnimation.default,
    });
  }, [animRef]);

  return (
    <div className="animated fadeIn">
      <Row
        style={{ marginTop: margin ? "20vh" : "" }}
        className="justify-content-center"
      >
        <Col xs="7" lg="2">
          <div
            className=""
            style={{ height: 300, width: 300 }}
            ref={(ref) => (animRef = ref)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Loading;
