import NonMainNavbar from "components/Navbars/NonMainNavbar";
import React from "react";
import { Container } from "reactstrap";
import AsadaFooter from "../components/AsdaFooter";

class Privacy extends React.Component {
  render() {
    return (
      <>
        <NonMainNavbar />
        <div style={{ height: '15vh' }} />
        <Container>
          {/* <Row>
            <Col md={{ size: 12, offset: 4 }} xs="12">
              <img
                src={require("../assets/img/brand/AsadaFullLogo.png").default}
                alt="Política Privacidad Asada Sarapiquí"
                className="justify-center"
              />
            </Col>
          </Row> */}

          <h3><strong>Pol&iacute;tica de privacidad</strong></h3>

          <p>El sitio web asadasarapiqui.com es propiedad de Asada sarapiqui, que es un sitio y una aplicaci&oacute;n de consulta de datos de clientes.</p>

          <p>Hemos adoptado esta Pol&iacute;tica de privacidad, que determina c&oacute;mo procesamos la informaci&oacute;n recopilada por asadasarapiqui.com, que tambi&eacute;n proporciona las razones por las que debemos recopilar ciertos datos personales sobre ti. Por lo tanto, debes leer esta Pol&iacute;tica de privacidad antes de usar el sitio web de asadasarapiqui.com.</p>

          <p>Cuidamos tus datos personales y nos comprometemos a garantizar su confidencialidad y seguridad.</p>

          <h4><strong>Informaci&oacute;n personal que recopilamos:</strong></h4>

          <p>Cuando visitas asadasarapiqui.com o utilizas nuestras apps. Puedes consultar datos pero no guardamos ning&uacute;n dato personal.</p>

          <p>Puedes visitar la web sin decirnos qui&eacute;n eres ni revelar ninguna informaci&oacute;n por la cual alguien pueda identificarte como una persona espec&iacute;fica. Sin embargo, si deseas utilizar algunas de las funciones del sitio web, o deseas recibir nuestro bolet&iacute;n informativo o proporcionar otros detalles al completar un formulario, puedes proporcionarnos datos personales, como tu correo electr&oacute;nico, nombre, apellido, ciudad de residencia, organizaci&oacute;n y n&uacute;mero de tel&eacute;fono. Puedes optar por no proporcionar tus datos personales, pero es posible que no puedas aprovechar algunas de las funciones del sitio web. Por ejemplo, no podr&aacute;s recibir nuestro bolet&iacute;n ni contactarnos directamente desde el sitio web. Los usuarios que no est&eacute;n seguros de qu&eacute; informaci&oacute;n es obligatoria pueden ponerse en contacto con nosotros a trav&eacute;s de info@asadasarapiqui.com.</p>

          <p>&nbsp;</p>

          <h4><strong>Enlaces a otros sitios web:</strong></h4>

          <p>Nuestra web puede contener enlaces a otros sitios web que no son de nuestra propiedad ni est&aacute;n controlados por nosotros. Ten en cuenta que no somos responsables de dichos sitios web ni de las pr&aacute;cticas de privacidad de terceros. Te recomendamos que est&eacute;s atento cuando abandones nuestro sitio web y leas las declaraciones de privacidad de cada web que pueda recopilar informaci&oacute;n personal.</p>

          <p>&nbsp;</p>

          <h4><strong>Seguridad de la informaci&oacute;n:</strong></h4>

          <p>Aseguramos la informaci&oacute;n que proporcionas en servidores inform&aacute;ticos en un entorno controlado y seguro, protegido del acceso, uso o divulgaci&oacute;n no autorizados. Mantenemos medidas de seguridad administrativas, t&eacute;cnicas y f&iacute;sicas razonables para proteger contra el acceso no autorizado, el uso, la modificaci&oacute;n y la divulgaci&oacute;n de datos personales bajo su control y custodia. Sin embargo, no se puede garantizar la transmisi&oacute;n de datos a trav&eacute;s de Internet o redes inal&aacute;mbricas.</p>

          <p>&nbsp;</p>

          <h4><strong>Divulgaci&oacute;n legal:</strong></h4>

          <p>Divulgaremos cualquier informaci&oacute;n que recopilemos, usemos o recibamos si as&iacute; lo requiere o lo permite la ley, como para cumplir con una citaci&oacute;n o un proceso legal similar, y cuando creemos de buena fe que la divulgaci&oacute;n es necesaria para proteger nuestros derechos, proteger tu seguridad o la seguridad de los dem&aacute;s, investigar el fraude o responder a una solicitud del gobierno.</p>

          <p>&nbsp;</p>

          <h4><strong>Informaci&oacute;n de contacto:</strong></h4>

          <p>Si deseas comunicarte con nosotros para comprender m&aacute;s sobre esta Pol&iacute;tica o deseas comunicarte con nosotros en relaci&oacute;n con cualquier asunto sobre los derechos individuales y tu informaci&oacute;n personal, puedes enviarnos un correo electr&oacute;nico a info@asadasarapiqui.com.</p>


          {/* <Row className="mt-5">
            <Col>
              <h3>Junta Directiva</h3>
            </Col>
          </Row> */}
          {/* <Row className="mt-4 mb-4">
            <Col>
              <img src={require("../assets/img/junta.png").default} alt="junta" style={{ width: '100%' }} />
            </Col>
          </Row> */}
        </Container>

        <AsadaFooter />
      </>
    );
  }
}

export default Privacy;
