import React, { useState } from "react";
import ReceiptItem from "./ReceiptItem";

const ReceiptsList = (props) => {
  const receipts = props.receipts;
  const [accordions, setAccordions] = useState(
    Array(receipts.length).fill(false)
  );

  const toggleAccordion = (index) => {
    const nState = [...accordions];
    nState[index] = !nState[index];
    setAccordions(nState);
    
  };

  return (
    <>
      <div>
        {receipts.map((receipt, index) => (
          <ReceiptItem
            key={index}
            receipt={receipt}
            accordions={accordions}
            toggle={toggleAccordion}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

export default ReceiptsList;
