/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// Asada Components
import AsadaHero from "../components/AsadaHero";
import AsadaMembers from "../components/AsadaMembers";
import AsadaNews from "../components/AsadaNews";
import AsadaFooter from "../components/AsdaFooter";

// index page sections
import MainNavbar from "components/Navbars/MainNavbar";
import initialize from "../services/Initialize";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  doInitialize = async () => {
    await initialize();
    this.setState({ loading: false });
  };

  componentDidMount() {
    if (this.state.loading) {
      this.doInitialize();
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  render() {
    if (this.state.loading) {
      return <div>loading...</div>;
    }

    return (
      <>
        <MainNavbar />
        <main ref="main">
          <AsadaHero />
          <section className="section">
            <Container>
              <div
                className="video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%" /* 16:9 */,
                  paddingTop: 25,
                  height: 0,
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src={"https://www.youtube.com/embed/U1Gnqt571sg"}
                  frameBorder="0"
                />
              </div>
            </Container>
          </section>
          <section className="section">
            <Container>
              <AsadaMembers />
            </Container>
          </section>

          <section className="section bg-secondary">
            <Container>
              <AsadaNews />
            </Container>
          </section>
        </main>
        <AsadaFooter />
      </>
    );
  }
}

export default Index;
