import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  InputGroup,
  Row,
  Col,
  Input,
  Button,
  Alert,
  FormFeedback,
} from "reactstrap";
import AppMessages from "../shared/AppMessages";
import ReceiptsConsumer from "./ReceiptsConsumer";
import { Context } from "./ReceiptContext";
import ReCaptcha from "react-google-recaptcha";

const ReceiptSearch = (props) => {
  const { updateReceipts } = props;
  const { register, errors, handleSubmit, watch } = useForm();
  const [searching, setSearching] = useContext(Context);
  const [searchResult, setSearchResult] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);

  const validate = () => {
    const searchNombre = watch("nombre");
    const searchCedula = watch("cedula");
    const searchPaja = watch("paja");

    return searchNombre || searchCedula || searchPaja
      ? true
      : AppMessages.formOneIsRequired;
  };

  const handleSearch = (data) => {
    setSearching(false);
    if (data === "failed") {
      setSearchResult(AppMessages.serviceUnavailable);
    } else if (data.length === 0) {
      setSearchResult(AppMessages.noResults);
    } else {
      setSearchResult("");
      updateReceipts(data);
    }
  };

  const onSubmit = (data) => {
    setSearching(true);
    ReceiptsConsumer(data.nombre, data.cedula, data.paja, handleSearch);
  };

  const handleVerifyCaptcha = (token) => {
    setCaptchaToken(token);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="margin-bottom-small">
        {searchResult && (
          <Row>
            <Col xs="12">
              <Alert color="danger">{searchResult}</Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm="12" md="3">
            <InputGroup>
              <Input
                placeholder="Nombre"
                type="text"
                name="nombre"
                autoComplete="off"
                innerRef={register({ validate })}
                className={errors.nombre && "is-invalid"}
                disabled={searching}
              />
            </InputGroup>
          </Col>
          <Col sm="12" md="3">
            <InputGroup>
              <Input
                type="number"
                name="cedula"
                autoComplete="off"
                placeholder="Cédula 102340567"
                innerRef={register({ validate, minLength: 9, maxLength: 9 })}
                className={errors.cedula && "is-invalid"}
                disabled={searching}
              />
              {errors.cedula &&
                (errors.cedula.type === "maxLength" ||
                  errors.cedula.type === "minLength") && (
                  <FormFeedback>
                    {AppMessages.requiredLength.replace("{x}", 9)}
                  </FormFeedback>
                )}
            </InputGroup>
          </Col>
          <Col sm="12" md="3">
            <InputGroup>
              <Input
                placeholder="Paja"
                type="number"
                name="paja"
                autoComplete="off"
                innerRef={register({ validate })}
                className={errors.paja && "is-invalid"}
                disabled={searching}
              />
            </InputGroup>
          </Col>
          <Col vsm="12" md="3">
            {!!!captchaToken && (
              <ReCaptcha
                sitekey="6LdZarQZAAAAAHaQ51E7Zg9blnE6R0oULzLmw7QK"
                onChange={handleVerifyCaptcha}
                hl="es-419"
                size="compact"
              />
            )}
            {!!captchaToken && (
              <Button type="submit" color="primary" disabled={searching}>
                Buscar
              </Button>
            )}
          </Col>
        </Row>
        {(errors?.cedula?.type === "validate" ||
          errors?.nombre?.type === "validate" ||
          errors?.paja?.type === "validate") && (
          <div>
            <p className="danger-text">{AppMessages.formOneIsRequired}</p>
          </div>
        )}
      </Form>
    </>
  );
};

export default ReceiptSearch;
