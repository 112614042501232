import React, {useState, useEffect} from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AsadaPostCard from "./AsadaPostCard";

const AsadaNews = (props) => {
  const [posts,setPosts]=useState([]);
  const getPosts=()=>{
    fetch('payloads/news.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setPosts(myJson)
      });
  }
  useEffect(()=>{
    getPosts()
  },[])

  if (posts.length === 0) {
    return <h3>No se encontraron noticias en este momento.</h3>;
  }

  return (
    <>
      <h3>Anuncios</h3>
      <Row>
        {posts.slice(0, 3).map((post, index) => (
          <AsadaPostCard key={index} post={post} />
        ))}
      </Row>
      <Row xs="12">
        <Col>
          <Link
            to="/anuncios"
            className="float-right"
            style={{ marginTop: "15px" }}
          >
            Ver más
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default AsadaNews;
