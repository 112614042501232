import React from "react";
import { Container, Row, Col } from "reactstrap";
import NonMainNavbar from "../components/Navbars/NonMainNavbar";
import AsadaFooter from "../components/AsdaFooter";

const NewService = () => {
  return (
    <>
      <NonMainNavbar />
      <div style={{ height: "15vh" }} />
      <Container>
        <Row>
          <Col>
            <h1>Solicitud de Nueva Conexión de Servicio de Agua Potable</h1>
            <p className="text-justify">
              Para solicitar un nuevo servicio solo debe seguir los siguientes
              simples pasos:
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs="1" className="numberList text-info">
            1
          </Col>
          <Col className="textList">
            Descarge el{" "}
            <a href="FormularioNuevoServicio.pdf" target="_blank">
              Formulario de solicitud de Nueva Conexión de Servicio de Agua
              Potable
            </a>{" "}
          </Col>
        </Row>
        <Row>
          <Col xs="1" className="numberList text-info">
            2
          </Col>
          <Col className="textList">
            Imprima el formulario, llene toda la infomación requerida
          </Col>
        </Row>
        <Row>
          <Col xs="1" className="numberList text-info">
            3
          </Col>
          <Col className="textList">Presentelo en nuestas oficinas</Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <iframe
              title="Mapa"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.2282252561367!2d-84.13438618460138!3d10.40343989257525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa085cfb93a17ab%3A0xa5727fb00bb42f57!2sAsada%20Integrada%20Sarapiqui!5e0!3m2!1sen!2scr!4v1594598383050!5m2!1sen!2scr"
              style={{
                width: "100%",
                height: "450px",
                border: 0,
              }}
            ></iframe>
          </Col>
        </Row>
      </Container>
      <div style={{ marginBottom: "3vh" }} />
      <AsadaFooter style={{ position: "relative", bottom: "0" }} />
    </>
  );
};

export default NewService;
