import React, { useEffect } from "react";
import NonMainNavbar from "components/Navbars/NonMainNavbar";
import AsadaFooter from "components/AsdaFooter";
import { Container } from "reactstrap";
import PostList from "../components/Posts/PostList";

const News = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <NonMainNavbar />
      <div style={{ height: "10vh" }} />
      <Container>
        <PostList />
      </Container>
      <AsadaFooter />
    </>
  );
};

export default News;
