import React, { useState, createContext } from 'react';
export const Context = createContext();

const ReceiptContext = (props) => {
  const [searching, setSearching] = useState(false);

  return(
    <Context.Provider value={[searching, setSearching]}>
      {props.children}
    </Context.Provider>
  )
}

export default ReceiptContext;