import PostsModel from "./PostsModel";
import MembersModel from "./MembersModel";
import EmployeesModel from "./EmployeesModel";
import FoundersModel from "./FoundersModel";

const StoreModel = {
  posts: PostsModel,
  members: MembersModel,
  employees: EmployeesModel,
  founders: FoundersModel,
}

export default StoreModel;