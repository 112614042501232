import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import NonMainNavbar from "components/Navbars/NonMainNavbar";
import AsadaFooter from "components/AsdaFooter";
import ReceiptsSearch from "components/receipt/ReceiptSearch";
import ReceiptsTable from "components/receipt/ReceiptsTable";
import ReceiptContext from "components/receipt/ReceiptContext";

const Receipts = () => {
  const [receipts, setReceipts] = useState(null);

  return (
    <>
      <NonMainNavbar />
      <Container>
        <div style={{ height: "15vh" }} />
        <h1>Consulta de Facturación</h1>
        <p>
          Para realizar la búsqueda solo necesita ingresar uno de los siguientes
          datos
        </p>
        <Row>
          <Col>
            <ReceiptContext>
              <ReceiptsSearch updateReceipts={setReceipts} />
              <ReceiptsTable receipts={receipts} />
            </ReceiptContext>
          </Col>
        </Row>
      </Container>
      <div className="mt-5" />
      <AsadaFooter />
    </>
  );
};

export default Receipts;
