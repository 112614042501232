/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

const AsadaFooter = () => {
  return (
    <>
      <footer className="footer" style={{ backgroundColor: "#f6f9fc" }}>
        <Container>
          <Row className=" row-grid align-items-center mb-5">
            <Col lg="6">
              <h3 className=" text-primary font-weight-light mb-2">
                Estamos para servirles!
              </h3>
              <h4 className=" mb-0 font-weight-light">
                Asada Sarapiquí <br /> Protegemos nuestro gran tesoro
              </h4>
            </Col>
            <Col className="text-lg-center btn-wrapper" lg="6">
              <Button
                className="btn-icon-only rounded-circle ml-1"
                color="facebook"
                href="https://www.facebook.com/ASADASarapiqui"
                id="facebookTooltip"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-facebook-square" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="facebookTooltip">
                Facebook
              </UncontrolledTooltip>
              <Button
                className="btn-icon-only rounded-circle ml-1"
                color="info"
                href="mailto:info@asadasarapiqui.com"
                id="emailTooltip"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-envelope" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="emailTooltip">
                Correo Electrónico: info@asadasarapiqui.com
              </UncontrolledTooltip>
              <Button
                className="btn-icon-only rounded-circle ml-1"
                color="primary"
                href="tel:27610265"
                id="phoneTooltip"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-phone" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="phoneTooltip">
                2761 0265
              </UncontrolledTooltip>
            </Col>
          </Row>
          <hr />
          <Row className=" align-items-center justify-content-md-between">
            <Col md="6">
              <div className=" copyright">
                © {new Date().getFullYear()} Asada Sarapiquí.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default AsadaFooter;
