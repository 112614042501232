import { action } from "easy-peasy";

const FoundersModel = {
  founders: "",
  setFounders: action((state, payload) => {
    state.founders = payload;
  }),
};

export default FoundersModel;
