import React, { useContext } from "react";
import { Table } from "reactstrap";
import ReceiptRow from "./ReceiptRow";
import { Context } from "./ReceiptContext";
import Loading from "../common/Loading";

const ReceiptsTable = (props) => {
  const { receipts } = props;
  const [searching] = useContext(Context);

  if (searching) {
    return <Loading margin={false} />;
  }

  if (!receipts || receipts.length === 0) {
    return <></>;
  }

  return (
    <Table hover responsive striped className="mt-5">
      <thead>
        <tr>
          <th>Paja</th>
          <th>Periodo</th>
          <th>Nombre</th>
          <th>Factura</th>
          <th>Vencimiento</th>
        </tr>
      </thead>
      <tbody>
        {receipts.map((receipt, index) => (
          <ReceiptRow key={index} receipt={receipt} />
        ))}
      </tbody>
    </Table>
  );
};

export default ReceiptsTable;
