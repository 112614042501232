import AsadaFooter from "components/AsdaFooter";
import NonMainNavbar from "components/Navbars/NonMainNavbar";
import PostHeader from "components/Posts/PostHeader";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Col, Container, Row, UncontrolledCarousel } from "reactstrap";

const Post = (props) => {
  const { post } = props.location.state;
  const images = [];

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <NonMainNavbar />
      <PostHeader title={post.title} timestamp={post.created_at} />
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col className="text-justify" lg="12">
            <ReactMarkdown children={post.body} className="post-body"  />
          </Col>
        </Row>
        {images.length > 0 && (
          <Row>
            <Col xs="12">
              <UncontrolledCarousel items={images} />
            </Col>
          </Row>
        )}
      </Container>
      <div style={{ marginBottom: "3vh" }} />
      <AsadaFooter style={{ position: "relative", bottom: "0" }} />
    </>
  );
};

export default Post;
