import NonMainNavbar from "components/Navbars/NonMainNavbar";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import AsadaFooter from "../components/AsdaFooter";

class About extends React.Component {
  render() {
    return (
      <>
        <NonMainNavbar />
        <div style={{ height: '15vh' }} />
        <Container>
          <Row>
            <Col md={{ size: 12, offset: 4 }} xs="12">
              <img
                src={require("../assets/img/brand/AsadaFullLogo.png")}
                alt="Junta Asada Sarapiquí"
                className="justify-center"
              />
            </Col>
          </Row>

          <p style={{ marginTop: "3vh" }} className="text-justify">
            El Acueducto de La Virgen nace en el año 1977, gracias a un grupo de
            personas pioneras de la zona, todos campesinos, algunos de ellos
            fueron: el Señor Carlos Duarte Salguero, Claudio Lara Campos, Carlos
            Salazar Carballo y la señora Sonia Campos Gómez; el propósito
            consistió en poder brindar el abastecimiento de agua potable a las
            familias de ese entonces, este grupo de personas en conjunto con
            ayuda de Asignaciones Familiares, Acueductos y Alcantarillados y
            vecinos de la comunidad, realizan un zanjeo de forma rudimentaria,
            pues no se contaba con la maquinaria especializada, se construye
            aproximadamente 12 Kms en el que se extiende una tubería de cuatro
            pulgadas, del tanque hacia las comunidades, terminando en dos
            pulgadas. Existiendo una población en ese entonces de 250 abonados.
            <br />
            <br />
            Pasado el tiempo, transcurrieron los años sin que nadie hiciera nada
            más que el simple mantenimiento de la tubería ya existente sin mirar
            hacia el futuro, la comunidad fue creciendo, se presenta la
            necesidad de otras comunidades vecinas y es aquí donde se da el
            toque de alarma. En el año 1994, el Sr. Víctor Porras Zamora, fue
            quien se interesó en la creación de un Comité Pro-Acueducto Rural y
            en conjunto con otras personas solicitaron una ayuda al Instituto
            Mixto de Ayuda Social por un monto de ₡25.000.000 para mejorar la
            tubería desde la fuente secundaria (F2) y poder abastecer a la
            población los cuales ya rondaban los 720 abonados. En el año 2000 la
            Embajada de Japón dona ₡16.000.000 para la compra de la tubería que
            va a las comunidades de San Isidro, Las Palmitas y Llano Grande. El
            Acueducto de La Virgen, actualmente se abastece de dos nacientes, F1
            y F2. La naciente F1 es compartida con el Acueducto de San Ramón, no
            obstante, dado el gran crecimiento de la comunidad de La Virgen, la
            cantidad de agua disponible no es suficiente para abastecer la
            demanda a corto plazo y por tanto la demanda futura. Las nacientes
            F1 y F2 de donde se abastece actualmente el Acueducto de La Virgen
            cuentan con los siguientes datos según las hojas de denuncia de
            Recurso Hídrico del AyA.
            <br />
            <br />
            <ul>
              <li>
                Naciente F1. 15.87 L/s, fecha 2 de febrero del 2006. Coordenadas
                Latitud 256.85, longitud 522.44.
              </li>
              <li>
                Naciente F2. 23.29 L/s, fecha 2 de febrero de 2006. Coordenadas
                Latitud 256.80. Longitud 522.45.
              </li>
            </ul>
            De acuerdo con el censo realizado se establecen datos en que se
            evidencia que el acueducto de La Virgen abastece cerca de 1790
            previstas y tiene una extensión 61,798.34 metros en líneas de
            tuberías de distribución las cuales hacen llegar el recurso hídrico
            aproximadamente a 18.500 habitantes, cuenta con cinco tanques de
            almacenamiento, tres tanques ubicados en la parte alta se conocen
            como lo Tanques de San Ramón (Cloración), con volúmenes de 100m³,
            90m³ y 80m³, respectivamente. A partir de estos salen dos líneas de
            tubería en diámetro 100mm (4”). Otro tanque de Zona Media llamado
            Tanque Macuá de 500m³, y el último tanque en el sector de Las
            Palmitas, con un volumen de 300m³. <br />
            <br />
            Es importante mencionar que, a raíz del terremoto en la zona de Vara
            Blanca y Cinchona, el 8 de enero del 2009, las nacientes F1 y F2, se
            vieron afectadas, reduciendo su caudal considerablemente, según la
            información brindada por la oficina de la Asociación de
            Administrativa de Acueducto Rural de La Virgen. Aun no se han
            realizado estudios técnicos, como aforos (medición del volumen de
            entrada del agua al tanque de almacenamiento). <br />
            <br />
            Por otra parte, se indica que también se brinda el servicio de agua
            potable en el distrito de Puerto Viejo, dándole agua a los pueblos
            de Barrio Jardín, La Guaria y Yacaré. Con la ayuda y asesoría de AyA
            se capta agua de una naciente ubicada en el Parque Nacional Braulio
            Carrillo llamada Naciente Guácimo, la cual da un caudal de 15 L/s
            para una cantidad de abonados de 1600
          </p>

          <Row className="mt-5">
            <Col>
              <h3>Junta Directiva</h3>
            </Col>
          </Row>
          <Row className="mt-4 mb-4">
            <Col>
              <img src={require("../assets/img/junta.png")} alt="junta" style={{ width: '100%' }} />
            </Col>
          </Row>
        </Container>

        <AsadaFooter />
      </>
    );
  }
}

export default About;
