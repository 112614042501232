import React, { useEffect } from "react";
import NonMainNavbar from "../components/Navbars/NonMainNavbar";
import AsadaFooter from "../components/AsdaFooter";
import { Container } from "reactstrap";
import { useParams } from "react-router";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import "react-tabs/style/react-tabs.css";

const Transparency = () => {
  const { year } = useParams();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <NonMainNavbar />
      <div style={{ height: "15vh" }} />

      <Container>
        <Accordion preExpanded={[year]} allowZeroExpanded={true}>
          <AccordionItem uuid={"2020"}>
            <AccordionItemHeading>
              <AccordionItemButton>Informes 2020</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Tabs>
                <TabList>
                  <Tab>Presidencia</Tab>
                  <Tab>Tesorería</Tab>
                  <Tab>ASP Mayor</Tab>
                  <Tab>ASP Inventarios</Tab>
                  <Tab>ASP Diario</Tab>
                </TabList>

                <TabPanel>
                  <h2>Informe de Presidencia</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/c3S5JeXUiSZcRw' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h2>Informe de Tesorería</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/iUVR5PWfrmx3po' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h2>ASP Libro Mayor</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/HqqxqJAVP0y4IA' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h2>ASP Libro Inventarios</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/It3eEn3rTCEZXw' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h2>ASP Libro Diario</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/3vGMaktUgd7kD7' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
              </Tabs>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid={"2021"}>
            <AccordionItemHeading>
              <AccordionItemButton>Informes 2021</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Tabs>
                <TabList>
                  <Tab>Comunicado</Tab>
                  <Tab>Presidencia</Tab>
                  <Tab>Tesorería</Tab>
                  <Tab>Fiscalia</Tab>
                  <Tab>Brochure</Tab>
                </TabList>
                <TabPanel>
                  <h2>Comunicado</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/gmFsJ7nsyTnV5v' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h2>Informe de Presidencia</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/IExXNU4cVotghI' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h2>Informe de Tesorería</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/pvYR7ljqbs9rVP' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h2>Fiscalía</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/apKumYWzitZKE8' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h2>Brochure</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<iframe src='https://www.slideshare.net/slideshow/embed_code/key/Fjw0I6CS5S2lXP' width='900' height='600' frameborder='0' marginwidth='0' marginheight='0' scrolling='no' style='border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;' allowfullscreen> </iframe>",
                    }}
                  />
                </TabPanel>
              </Tabs>
            </AccordionItemPanel>
          </AccordionItem>
          {/* <AccordionItem uuid={"2022"}>
            <AccordionItemHeading>
              <AccordionItemButton>Informes 2022</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Tabs>
                <TabList>
                  
                </TabList>

                
              </Tabs>
            </AccordionItemPanel>
          </AccordionItem> */}
        </Accordion>
      </Container>
      <div style={{ marginBottom: "3vh" }} />
      <AsadaFooter style={{ position: "relative", bottom: "0" }} />
    </>
  );
};

export default Transparency;
