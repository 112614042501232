import { action } from "easy-peasy";

const EmployeesModel = {
  employees: "",
  setEmployees: action((state, payload) => {
    state.employees = payload;
  }),
};

export default EmployeesModel;
