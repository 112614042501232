/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Col,
  Container,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import NavDropdownMediaItem from "./NavDropdownMediaItems";

class DemoNavbar extends React.Component {
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main bg-info navbar-light"
            expand="lg"
            id="navbar-main"
            fixed="top"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/asada-logo.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/asada-logo.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink href="/anuncios" id="tooltipAnuncios">
                      Anuncios <span className="sr-only">(current)</span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltipAnuncios">
                      Anuncios referentes a los servicios de agua
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="fa fa-building d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Servicios</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        {/* <NavDropdownMediaItem
                          title="Averias"
                          body="Reporte de averias en nuestros servicios"
                          icon="ni ni-settings"
                          linkTo="/"
                        />*/}

                        <NavDropdownMediaItem
                          title="Facturación"
                          body="Consultas de la facturación de su servicio"
                          icon="ni ni-credit-card"
                          linkTo="/recibos"
                        /> 

                        <NavDropdownMediaItem
                          title="Nuevo servicio"
                          body="Realice una solicitud para la instalación del
                              servicio"
                          icon="ni ni-square-pin"
                          linkTo="/nuevoservicio"
                        />
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  {/* <NavItem>
                    <NavLink to="/about" tag={Link} id="tooltipNosotros">
                      Nosotros <span className="sr-only">(current)</span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltipNosotros">
                      Sobre Asada Sarapiquí
                    </UncontrolledTooltip>
                  </NavItem> */}
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="fa fa-building d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Nosotros</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">

                        <NavDropdownMediaItem
                          title="Acerca de"
                          body="Sobre Asada Sarapiquí"
                          icon="ni ni-credit-card"
                          linkTo="/about"
                        />

                        <NavDropdownMediaItem
                          title="Fundadores"
                          body="Nuestras raíces, los que hicieron historia."
                          icon="ni ni-square-pin"
                          linkTo="/fundadores"
                        />
                        <NavDropdownMediaItem
                          title="Política de Privacidad"
                          body="Política de privacidad de nuestras aplicaciones"
                          icon="ni ni-box-2"
                          linkTo="/privacidad"
                        />
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-center" navbar>
                  <NavItem>
                    <Link
                      to="/transparencia"
                      id="tooltipTransparencia"
                      className="nav-link"
                    >
                      Transparencia <span className="sr-only">(current)</span>
                    </Link>
                    <UncontrolledTooltip delay={0} target="tooltipTransparencia">
                      Anuncios referentes a los estados financieros
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-center" navbar>
                  <NavItem>
                    <Link
                      to="/galeria"
                      id="tooltipGaleria"
                      className="nav-link"
                    >
                      Galería <span className="sr-only">(current)</span>
                    </Link>
                    <UncontrolledTooltip delay={0} target="tooltipGaleria">
                      Galería de Proyectos
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.facebook.com/ASADASarapiqui"
                      id="tooltipFacebook"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltipFacebook">
                      Siguenos en Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://play.google.com/store/apps/details?id=com.asada.sarapiqui"
                      id="tooltipAndroidApp"
                      target="_blank"
                    >
                      <i className="fa fa-android" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Aplicación Android
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltipAndroidApp">
                      Descarga nuestra aplicación para teléfonos Android
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
