import React from "react";
import { Col } from "reactstrap";

const MemberCard = ({ member }) => {
    
  return (
    <Col sm="3" xs="6" className="text-center">
      <small className="d-block text-uppercase font-weight-bold mb-4">
        {member.position}
      </small>
      <img
        alt="..."
        className="img-fluid rounded-circle shadow"
        src={member.url}
        style={{ width: "150px", height: "150px", }}
      />
      <small className="d-block text-uppercase font-weight-bold mb-4 mt-3 ">
        {member.name}
      </small>
    </Col>
  )
};

export default MemberCard;
