import { Col, Container, Row } from "reactstrap";
import { formatTimestamp } from "../../Utils";

const PostHeader = (props) => {
  const { title, timestamp } = props;

  return (
    <div className="post-header bg-info">
      <div style={{ height: '100px'}} />
      <Container>
        <Row className="align-items-center justify-content-center text-center">
          <Col xs="12" className="text">
            <h1 className="text-secondary">{title}</h1>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center text-center">
          <Col xs="12" className="text">
            <img
              alt="..."
              className="img-fluid rounded-circle"
              src={require("assets/img/theme/asada-avatar.png")}
              style={{ width: "50px", marginRight: "10px" }}
            />
            <span className="timestamp text-secondary">
              {formatTimestamp(timestamp)}
            </span>
          </Col>
        </Row>
      </Container>
      <div style={{ height: '5vh' }} />
    </div>
  );
};

export default PostHeader;
