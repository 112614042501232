import React from "react";
import { Card, TabPane } from "reactstrap";

const AsadaCard = (props) => {
  const { title, body } = props;
  return (
    <Card className="shadow" style={{marginBottom: '2vh', padding: '1vh'}}>
      <TabPane>
        <h3>{title}</h3>
        <p style={{ fontStyle: 'italic' }}>{body}</p>
      </TabPane>
    </Card>
  );
};

export default AsadaCard;
