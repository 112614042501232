// const SERVER = "https://admin.asadasarapiqui.com:8443/api";
const SERVER = "http://190.211.127.120:8082";
const AUTH = "Basic cmVzdHVzZXI6QWMxNzE3U1JRNjUy";
const SEARCH = "/recibo?nombre={NOMBRE}&cedula={CEDULA}&paja={PAJA}";
const RECEIPT = "/recibo/paja/{id}";

export const receiptSearch = async (nombre, cedula, paja, signal) => {
  const url =
    SERVER +
    SEARCH.replace("{NOMBRE}", nombre)
      .replace("{CEDULA}", cedula)
      .replace("{PAJA}", paja);

  try {
    fetch(url, {
      headers: { Authorization: AUTH },
    })
      .then((response) => handleErrors(response))
      .then((res) => res.json())
      .then((json) => signal(json))
      .catch((error) => signal("failed"));
  } catch (error) {
    signal("failed");
  }
};

export const getReceipts = async (pajaId, signal) => {
  const url = SERVER + RECEIPT.replace("{id}", pajaId);

  try {
    fetch(url, {
      headers: { Authorization: AUTH },
    })
      .then((response) => handleErrors(response))
      .then((res) => res.json())
      .then((json) => signal(json))
      .catch((error) => signal("failed"));
  } catch (error) {
    signal("failed");
  }
};

const handleErrors = (response, signal) => {
  if (!response.ok) {
    throw Error("Failed web request");
  }

  return response;
};