const AppMessages = {
  serviceUnavailable: "El servicio no está disponible en este momento. Por favor intente más tarde.",
  serviceError: "Ocurrió un error al intentar completar esta solicitud.",
  noResults: "No se encontraron resultados.",
  noData: "No hay datos",
  formIsRequired: 'Es un dato requerido',
  formOneIsRequired: 'Debe ingresar al menos uno de los datos',
  loginInvalid: "El nombre de usuario o contaseña no son correctos",
  requiredLength: "Debe tener {x} caracteres",
  minLength: "Debe teneral menos {x} caracters",
  maxLength: "No debe tener más de {x} carateres",
  messageSend: "El mensaje fue enviado",
  noMessages: "No se encontraron mensajes"
};

export default AppMessages;
