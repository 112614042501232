//export const CMS_SERVER = "https://admin.asadasarapiqui.com:8443/cms";
export const CMS_SERVER = "http://201.205.241.10:8086";
const POSTS_ENDPOINT = "/posts";
const MEMBERS_ENDPOINT = "/members?_sort=displayOrder:ASC";
const EMPLOYEES_ENDPOINT = "/employees/2"
const FOUNDERS_ENDPOINT = "/founders/1"

const validateErrors = (response) => {
  if (!response.ok) {
    throw new Error("Request failed");
  }

  return response;
};

export const getPosts = async (callback) => {
  let filter = "?_sort=created_at:DESC";
  
  try {
    fetch(CMS_SERVER + POSTS_ENDPOINT + filter)
      .then((response) => validateErrors(response))
      .then((response) => response.json())
      .then((json) => callback(json))
      .catch((error) => {
        console.log(error);
        callback("failed");
      });
  } catch (error) {
    console.log(error);
    callback("failed");
  }
};

export const getMembers = async (callback) => {
  try {
    fetch(CMS_SERVER + MEMBERS_ENDPOINT)
      .then((response) => validateErrors(response))
      .then((response) => response.json())
      .then((json) => callback(json))
      .catch((error) => {
        console.log(error);
        callback("failed");
      });
  } catch (error) {
    console.log(error);
    callback("failed");
  }
};

export const getEmployees = async (callback) => {
  try {
    fetch(CMS_SERVER + EMPLOYEES_ENDPOINT)
      .then((response) => validateErrors(response))
      .then((response) => response.json())
      .then((json) => callback(json))
      .catch((error) => {
        console.log(error);
        callback("failed");
      });
  } catch (error) {
    console.log(error);
    callback("failed");
  }
}

export const getFounders = async (callback) => {
  try {
    fetch(CMS_SERVER + FOUNDERS_ENDPOINT)
      .then((response) => validateErrors(response))
      .then((response) => response.json())
      .then((json) => callback(json))
      .catch((error) => {
        console.log(error);
        callback("failed");
      });
  } catch (error) {
    console.log(error);
    callback("failed");
  }
}