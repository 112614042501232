import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
} from "reactstrap";
import { shortenText } from "../Utils";

const AsadaPostCard = (props) => {
  const { post } = props;

  // const imageSrc = post.pictures[0]
  //   ? CMS_SERVER.concat(post.pictures[0].formats.thumbnail.url)
  //   : require("../assets/img/brand/postDefault.png").default;

  return (
    <Col xs="12" md="4" style={{marginTop: '4vh'}}>
      <Card>
        <CardImg
          top
          width="100%"
          src={post.url}
          alt="Card image cap"
          className="post-background"
        />
        <CardBody>
          <CardTitle>{post.title}</CardTitle>
          <CardSubtitle></CardSubtitle>
          <CardText>
            <ReactMarkdown children={shortenText(post.body)} />
          </CardText>
          <Link
            to={{ pathname: "/post", state: { post: post } }}
            className="btn btn-info"
          >
            Ver
          </Link>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AsadaPostCard;
