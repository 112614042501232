import React from "react";
import { Container } from "reactstrap";
import NonMainNavbar from "components/Navbars/NonMainNavbar";
import AsadaFooter from "components/AsdaFooter";

const Receipts = () => {

  return (
    <>
      <NonMainNavbar />
      <Container>
        <div style={{ height: "15vh" }} />
        <h1>Página en Construcción.</h1>
      </Container>
      <div className="mt-5" />
      <AsadaFooter />

    </>
  );
};

export default Receipts;
