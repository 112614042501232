import React, {useEffect, useState} from "react";
import { Row, Col } from "reactstrap";
import MemberCard from "./members/MemberCard";
import { Link } from "react-router-dom";

const AsadaMembers = () => {
  const [data,setData]=useState([]);
  const getData=()=>{
    fetch('payloads/members.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])
  return (
    <>
      <h2 className="mt-lg mb-5">
        <span>Miembros de Junta</span>
      </h2>
      <Row>
        {data.slice(0, 4).map((member, index) => (
          <MemberCard key={index} member={member} />
        ))}
      </Row>
      <Row>
        <Col>
          <Link
            to="/miembros"
            className="float-right"
            style={{ marginTop: "15px" }}
          >
            Ver más
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default AsadaMembers;
