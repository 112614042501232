import { action } from "easy-peasy";

const PostModel = {
  posts: [],
  setPosts: action((state, payload) => {
    state.posts = payload;
  }),
};

export default PostModel;
