/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { StoreProvider } from "easy-peasy";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import store from "./services/session/Store";

import "assets/css/asada.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/vendor/nucleo/css/nucleo.css";

import About from "views/About";
import Founders from "views/Founders";
import Gallery from "views/Gallery";
import Index from "views/Index";
import Members from "views/Members";
import NewService from "views/NewService";
import News from "views/News";
import Post from "views/Post";
import Privacy from "views/Privacy";
import ReceiptDetails from "views/ReceiptDetails";
import Receipts from "views/Receipts";
import Transparency from "views/Transparency";

ReactDOM.render(
  <StoreProvider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Index {...props} />} />
        <Route
          path="/transparencia/"
          exact
          render={(props) => <Transparency {...props} />}
        />
        <Route
          path="/transparencia/:year"
          exact
          render={(props) => <Transparency {...props} />}
        />
        <Route path="/about" exact render={(props) => <About {...props} />} />
        <Route path="/post" exact render={(props) => <Post {...props} />} />
        <Route path="/anuncios" exact render={(props) => <News {...props} />} />

        <Route
          path="/galeria"
          exact
          render={(props) => <Gallery {...props} />}
        />
        <Route
          path="/miembros"
          exact
          render={(props) => <Members {...props} />}
        />
        <Route
          path="/nuevoservicio"
          exact
          tittle="Nuevo Servicio"
          render={(props) => <NewService {...props} />}
        />
        <Route
          path="/fundadores"
          exact
          tittle="Fundadores"
          render={(props) => <Founders {...props} />}
        />
        <Route
          path="/privacidad"
          exact
          tittle="Privacidad"
          render={(props) => <Privacy {...props} />}
        />
        <Route
          path="/recibos"
          exact
          render={(props) => <Receipts {...props} />}
        />
        <Route
          path="/recibos/:pajaId"
          exact
          render={(props) => <ReceiptDetails {...props} />}
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById("root")
);
