import { format } from "date-fns";
import { CMS_SERVER } from "./services/CmsApi";

export const formatTimestamp = (timestamp) => {
  return format(new Date(timestamp), "dd-MM-yyy pp");
};

export const getBiggestSizeAvailable = (formats) => {
  if (!formats) return "";

  if (formats.large) return "large";
  if (formats.medium) return "medium";
  if (formats.small) return "small";
  if (formats.thumbnail) return "thumbnail";

  return "";
};

export const shortenText = (text) => {
  if (text.length <= 120) {
    return text;
  } else {
    return text.substring(0, 119);
  }
};

export const getAvatarFromCms = (picture) => {
  debugger;
  if (!picture) return require("assets/img/theme/asada-avatar.png");
  const size = getBiggestSizeAvailable(picture[0].formats);

  return CMS_SERVER + picture[0].formats[size].url;
};

export const getAvatarFromLocal = (picture) => {
  debugger;
  return picture;
};
