import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router-dom";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { formatTimestamp, shortenText } from "../../Utils";
import PostIcon from "./PostIcon";

const PostItem = ({ props }) => {
  const { post } = props.location.state;
  const history = useHistory();

  const handleElementCLick = () => {
    history.push({ pathname: "/post", state: { post: post } });
  };

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: "#f4f5f7", color: "#000" }}
      date={formatTimestamp(post.created_at)}
      dateClassName=".vertical-timeline-date"
      iconStyle={{ background: "#fff", color: "#fff", padding: "9px" }}
      icon={<PostIcon />}
      onTimelineElementClick={handleElementCLick}
    >
      <h3 className="vertical-timeline-element-title">{post.title}</h3>
      <p> <ReactMarkdown children={shortenText(post.body)}/></p>
    </VerticalTimelineElement>
  );
};

export default PostItem;
