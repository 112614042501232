import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Col, Container, Row } from "reactstrap";
import AsadaFooter from "../components/AsdaFooter";
import NonMainNavbar from "../components/Navbars/NonMainNavbar";
// import foundersSADA from '../assets/founders.json';

const Founders = (props) => {
 //const { founders } = props.location.state;
  const [foundersSADA,setFoundersSADA]=useState([]);
  const getFoundersSADA=()=>{
    fetch('payloads/founders.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setFoundersSADA(myJson)
      });
  }
  useEffect(()=>{
    getFoundersSADA()
  },[])
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <NonMainNavbar />
      <div style={{ height: "15vh" }} />
      <Container>
        <Row className="align-items-center justify-content-center mb-4">
          <Col xs="12">
            <h1>Fundadores</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactMarkdown children={foundersSADA}/>
          </Col>
          {/* <Col>
            <ReactMarkdown source={founders} />
          </Col>
          <Col>
            <ReactMarkdown source={founders} />
          </Col> */}
        </Row>
      </Container>
      <div style={{ marginBottom: "3vh" }} />
      <AsadaFooter style={{ position: "relative", bottom: "0" }} />
    </>
  );
};
export default Founders;
